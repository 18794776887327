import React, { useCallback, useEffect, useState } from "react";
import { Col, Row, Button, Input, Card, CardBody, CardHeader } from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import {
  getDeliveryItems,
  addNewDeliveryItems,
  deleteDeliveryItems,
  clearDeliveryItemError,
} from "store/actions";
import ConfirmDeleteModal from "components/Common/ConfirmDeleteModal";
import SaleOrderSelectionModal from "containers/sale-order/SaleOrderSelectionModal";
import DeliveryRowCard from "./DeliveryRowCard";
import CustomPagination from "components/Common/CustomPagination";
import { debounce } from "lodash";

const DeliveryItemTab = props => {
  const {
    deliveryItem,
    deliveryStatus,
    deleteDeliveryItems,
    getDeliveryItems,
    addNewDeliveryItems,
    deliveryId
  } = props;
  
  const { items, itemCount, pageCount, loading } = deliveryItem;
  
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [term, setTerm] = useState("");
  const [saleOrders, setSaleOrders] = useState([]);
  const [modalConfirmDeleteIsOpen, setModalConfirmDeleteIsOpen] = useState(false);
  const [modalAddOrder, setModalAddOrder] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  
  const debouncedQuickSearch = useCallback(
    debounce(term => {
      setTerm(term);
      setPage(1);
    }, 500),
    []
  );
  
  useEffect(() => {
    getDeliveryItems({
      deliveryId,
      page,
      pageSize,
      term
    });
  }, [deliveryId, term, page, pageSize, getDeliveryItems]);

  useEffect(() => {
    if (items) {
      setSaleOrders(
        items.map((item) => ({
          ...item,
          isSelect: false,
        }))
      );
    }
  }, [items]);
  
  const handleHeaderCheckChange = e => {
    const { checked } = e.target;
    setSelectAll(checked);
    setSaleOrders(
      saleOrders.map(a => ({
        ...a,
        isSelect: checked,
      }))
    );
  };

  const handleRowCheck = (id, e) => {
    const { checked } = e.target;
    setSaleOrders(prevOrders => 
      prevOrders.map(a => 
        a.id === id ? { ...a, isSelect: checked } : a
      )
    );
  };

  const handleOnRemove = () => {
    setModalConfirmDeleteIsOpen(true);
  };

  const handleDelete = () => {
      deleteDeliveryItems({
        data: saleOrders.filter(e => e.isSelect).map(a => a.id),
        queryParams:{
          deliveryId,
          page,
          pageSize
        }
      })
    
    setPage(1);
    setModalConfirmDeleteIsOpen(false);
  };

  const handleOnSubmit = (selectedOrders) => {
    addNewDeliveryItems({
      data:{
        deliveryId,
        type: "SaleOrder",
        itemIds: selectedOrders.map((order) => order.id),
      },
      queryParams: {
        page,
        pageSize,
        term,
        deliveryId
      }
    })

    setModalAddOrder(false);
  };

  return (
    <>
      <Row>
        <Col md={12}>
          <Card className="mb-1 rounded-3">
            <CardHeader className="bg-transparent border pb-0 pt-3">
              <Row className="g-1">
                <Col md={2}>
                  <div className="search-box me-2 mb-2 d-inline-block">
                    <div className="position-relative">
                      <Input
                        type="text"
                        placeholder="Quick Search ..."
                        onChange={e => debouncedQuickSearch(e.target.value)}
                      />
                      <i className="fas fa-search search-icon" />
                    </div>
                  </div>
                </Col>
                <Col md={10} className="text-sm-end">
                  {deliveryStatus === 0 && (
                    <>
                      <Button
                        type="button"
                        color="primary"
                        className="me-1"
                        onClick={() => setModalAddOrder(true)}
                      >
                        <i className="fas fa-plus" /> Add Order
                      </Button>
                      <Button
                        type="button"
                        outline
                        disabled={!saleOrders.some(e => e.isSelect)}
                        color="danger"
                        className="me-1"
                        onClick={handleOnRemove}
                      >
                        <i className="fas fa-trash" /> Remove
                      </Button>
                    </>
                  )}
                </Col>
              </Row>
            </CardHeader>
          </Card>

          <Card>
            <CardBody>
              <div className="table-rep-plugin">
                {loading ? (
                  <div className="d-flex justify-content-center">
                    <div
                      className="spinner-border text-primary m-1"
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div style={{ padding: 10 }}>
                      <Input
                        type="checkbox"
                        onChange={handleHeaderCheckChange}
                        checked={selectAll}
                        readOnly
                      />
                      <span style={{ paddingLeft: 10 }}>Select All</span>
                    </div>
                    {saleOrders.length > 0 &&
                      saleOrders.map((item, index) => {
                        let num = (page - 1) * pageSize + (index + 1);
                        return (
                          <DeliveryRowCard
                            key={item.id}
                            onRowCheck={e => handleRowCheck(item.id, e)}
                            item={item}
                            num={num}
                          />
                        );
                      })}
                  </div>
                )}
              </div>
              <CustomPagination
                itemCount={itemCount}
                currentPage={page}
                totalPage={pageCount}
                defaultPageSize={pageSize}
                pageSizeOptions={[5, 10, 20, 30, 40, 50, 100]}
                onChangePage={setPage}
                onPageSizeChange={size => {
                  setPage(1);
                  setPageSize(size);
                }}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>

       <SaleOrderSelectionModal
        title="Sale Order Selection"
        toggle={() => setModalAddOrder(!modalAddOrder)}
        isOpen={modalAddOrder}
        onSelected={handleOnSubmit} 
        orderParams={{ deliveryStatuses: [1] }}
        enableUserRequestedFilter
      />  

      <ConfirmDeleteModal
        title="Confirm Delete"
        isOpen={modalConfirmDeleteIsOpen}
        toggle={() => setModalConfirmDeleteIsOpen(!modalConfirmDeleteIsOpen)}
        onSubmit={handleDelete}
      />
    </>
  );
};

DeliveryItemTab.propTypes = {
  deliveryItem: PropTypes.object.isRequired,
  deliveryId: PropTypes.string,
  itemCount: PropTypes.number,
  pageCount: PropTypes.number,
  getDeliveryItems: PropTypes.func.isRequired,
  addNewDeliveryItems: PropTypes.func.isRequired,
  deleteDeliveryItems: PropTypes.func.isRequired,
  deliveryStatus: PropTypes.number.isRequired,
  clearDeliveryItemError: PropTypes.func,
  history: PropTypes.object,
};

const mapStateToProps = ({ deliveryItem }) => ({
  deliveryItem,
});

export default withRouter(
  connect(mapStateToProps, {
    getDeliveryItems,
    addNewDeliveryItems,
    clearDeliveryItemError,
    deleteDeliveryItems,
  })(DeliveryItemTab)
);
