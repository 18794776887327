import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import { withRouter } from "react-router-dom";
import { Badge, Container, Row, Col, Nav, NavItem, NavLink, TabPane, TabContent } from "reactstrap";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Breadcrumbs from "components/Common/Breadcrumb";
import { NIL } from "uuid";
import classnames from "classnames";

import {
  getDeliveryById,
  updateDelivery,
  updateSaleOrder,
  getSaleOrderById,
  getDeliveryItems,
} from "store/actions";

import Form from "containers/delivery/Form";
import DeliveryItemTab from "./DeliveryItemTab";
import { getCompanyName } from "../../helpers/utils";

const DeliveryDetail = (props) => {
  const { 
    getDeliveryById, 
    updateDelivery, 
    getOrderAttachments, 
    getDeliveryItems, 
    deliveryItem = {},
 
  } = props;

  const history = useHistory();
  const { id: paramId } = useParams();

  const [id, setId] = useState(NIL);
  const [activeTab, setActiveTab] = useState("1");

  useEffect(() => {
    if (paramId !== NIL) {
      setId(paramId);
      getDeliveryById(paramId);
      getDeliveryItems({ deliveryId: paramId }); 
    }
  }, [paramId, getDeliveryById, getDeliveryItems]);


  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const handleSubmit = (saleOrder) => {
    const payload = { history, data: saleOrder };
    props.updateSaleOrder(payload);
  };

  const handleCancel = () => {
    history.push("/delivery");
  };

  const handleOnBackToList = () => {
    history.push("/delivery");
  };

  // const handlePopulateDeliveryItem = () => {
  //   props.getDeliveryItems({ deliveryId: id });
  // };

  const handlePopulateDeliveryItem = () => {
    const { getDeliveryItems } = props;
    getDeliveryItems({ deliveryId: id });
  };
  
  const handleNewItemsAdded = () => {
    getDeliveryItems({ deliveryId: id });
  };

const handlePopulateDelivery = () => {
  const { getDeliveryById } = props;
  getDeliveryById(id);
};


  // const handlePopulateTransportNote = () => {
  //   props.getTransportNote({ transportId: id });
  // };

  const handleOnSubmit = (data) => {
    props.updateDelivery(data);
  };

  const { delivery } = props;
  const { item, loading } = delivery;

  const itemCount = deliveryItem?.itemCount ?? 0;

  return (
    <div className="page-content">
      <MetaTags>
        <title>Delivery | {getCompanyName()}</title>
      </MetaTags>
      <Container fluid>
        <Breadcrumbs
          title="Home"
          backLink={"/delivery"}
          breadcrumbItem="Delivery Detail"
        />
        <Row>
          <Col lg={12}>
            <Nav tabs>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({ active: activeTab === "1" })}
                  onClick={() => {
                    toggle("1");
                    handlePopulateDelivery();
                  }}
                >
                  General
                </NavLink>
              </NavItem>
              <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({ active: activeTab === "2" })}
                      onClick={() => {
                        toggle("2");
                        handlePopulateDeliveryItem();
                      }}
                    >
                      Items
                      <Badge color="success" className="ms-1">
                        {itemCount}
                      </Badge>
                    </NavLink>
                  </NavItem>
            </Nav>

            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                {loading ? (
                  <div className="d-flex justify-content-center">
                    <div
                      className="spinner-border text-primary m-1"
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : (
                  item && (
                    <Form
                      item={item}
                      onSubmit={handleOnSubmit}
                      showStatus
                    />
                  )
                )}
              </TabPane>
              <TabPane tabId="2">
                {id !== NIL && (
                  <DeliveryItemTab
                    deliveryId={id}
                    deliveryStatus={item?.status ?? 0}
                    onNewItemsAdded={handleNewItemsAdded}
                  />
                )}
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

DeliveryDetail.propTypes = {
  delivery: PropTypes.object,
  deliveryItem: PropTypes.object,
  loading: PropTypes.bool,
  history: PropTypes.object,
  match: PropTypes.object,
  getDeliveryById: PropTypes.func.isRequired,
  getSaleOrderById: PropTypes.func.isRequired,
  updateDelivery: PropTypes.func.isRequired,

  updateSaleOrder: PropTypes.func.isRequired,
  getDeliveryItems: PropTypes.func.isRequired,
};

const mapStateToProps = ({ delivery, deliveryItem }) => ({
  delivery,
  deliveryItem,
});

export default withRouter(
  connect(mapStateToProps, {
    getDeliveryById,
    updateDelivery,

    updateSaleOrder,
    getSaleOrderById,
    getDeliveryItems,
  })(DeliveryDetail)
);

