import React, { Component } from "react";
import NumberFormat from "react-number-format";
import PropTypes from "prop-types";
import { Col, Input, Row } from "reactstrap";
import { NIL } from "uuid";

export class SaleOrderAdditionalEntryForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: NIL,
      saleOrderId: NIL,
      
      title: "",
      amount: "",
    };
  }

  handleChange = e => {
    const { value, name } = e.target;
    const { item } = this.props;

    switch (name) {
      case "title":
        item.title = value;
        break;
    }

    this.props.onChange(item);
  };

  handleOnAmountChange = value => {
    const { item } = this.props;
    item.amount = value;
    this.props.onChange(item);
  };

  render() {
    const { item, isPrimary, onAdd, onRemove } = this.props;
    const { title, amount } = item;
    
    return (
      <div>
        <Row>
          <Col>
            <div className="mb-2">
              <Input
                type="text"
                name="title"
                placeholder="Description"
                value={title}
                onChange={this.handleChange}
              />
            </div>
          </Col>
          <Col md={3}>
            <div className="mb-2 me-4">
              <NumberFormat
                name="amount"
                className="form-control text-end"
                placeholder="0"
                value={amount}
                thousandSeparator={true}
                onValueChange={obj => {
                  const { value } = obj;
                  this.handleOnAmountChange(value);
                }}
              />
            </div>
            <div
                style={{ position: "absolute", right: "0", top: "7px" }}
                onClick={isPrimary ? () => onAdd() : () => onRemove(item)}
            >
              <span
                  className="font-size-16 text-primary p-2"
                  style={{ cursor: "pointer" }}
              >
                <i
                    className={isPrimary ? "fas fa-plus" : "fas fa-trash-alt"}
                ></i>
              </span>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

SaleOrderAdditionalEntryForm.propTypes = {
  item: PropTypes.object,
  isPrimary: PropTypes.bool,
  onAdd: PropTypes.func,
  onRemove: PropTypes.func,
  onChange: PropTypes.func,
};

export default SaleOrderAdditionalEntryForm;
